<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'1000px'"
    persistent>
    <v-card>
      <material-card
        :title="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO ? $t('voucherProduct.updateInfo') : (actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL ? $t('voucherProduct.putOnSalesChannel') : $t('voucherProduct.removeFromSalesChannel'))"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <div
            v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
            class="d-inline-block mb-4">
            <span
              :style="tabSelected === tabsSelect.INFO ? 'border-bottom: 2px solid green;' : ''"
              class="mr-2 cursor-pointer"
              @click="onTabSelect(tabsSelect.INFO)">{{ $t('voucherProduct.productInfo') }}</span>
            <span
              :style="tabSelected === tabsSelect.CONFIG ? 'border-bottom: 2px solid green;' : ''"
              class="ml-2 cursor-pointer"
              @click="onTabSelect(tabsSelect.CONFIG)">{{ $t('voucherProduct.productConfig') }}</span>
            <span
              :style="tabSelected === tabsSelect.ADDRESS ? 'border-bottom: 2px solid green;' : ''"
              class="ml-3 cursor-pointer"
              @click="onTabSelect(tabsSelect.ADDRESS)">{{ $t('evoucher.detail.usageSite') }}</span>
          </div>
          <v-form
            v-if="tabSelected === tabsSelect.INFO"
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  :disabled="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                  v-model="issuanceProduct"
                  :items="issuanceProductList"
                  :clearable="true"
                  :label="$t('assignVoucher.selectTheWorkPieceCode')"
                  class="required"
                  @change="onIssuanceProductChange"
                >
                  <template v-slot:item="data">
                    <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                v-else
                xs12
                sm4
                md4
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  :disabled="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                  v-model="issuanceProduct"
                  :items="issuanceProductList"
                  :clearable="true"
                  :label="$t('assignVoucher.selectTheWorkPieceCode')"
                  class="required"
                  @change="onIssuanceProductChange"
                >
                  <template v-slot:item="data">
                    <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL"
                xs12
                sm4
                md4
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="issuanceId"
                  :items="issuanceList"
                  :clearable="true"
                  :label="$t('assignVoucher.chooseIssuance')"
                  class="required"
                  @change="onChangeIssuancce"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="issuanceId"
                  :items="issuanceList"
                  :clearable="true"
                  :label="$t('assignVoucher.chooseIssuance')"
                  class="required"
                  @change="onChangeIssuancce"
                />
              </v-flex>
              <!-- <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm4
                md4
              >
                <v-autocomplete
                  :disabled="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL || actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                  v-model="categoriesIds"
                  :items="categoriesList"
                  :clearable="true"
                  :label="$t('voucherProduct.category')"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ categoriesIds.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                </v-autocomplete>
              </v-flex> -->
              <!--  -->
              <!-- <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm4
                md4
              >
                <v-checkbox
                  v-model="bestSelling"
                  :label="$t('voucherProduct.bestSelling')" />
              </v-flex> -->
              <!-- Store channel -->
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL"
                xs12
                sm4
                md4
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="storeChannelId"
                  :items="storeChannelsList"
                  :clearable="true"
                  :label="$t('storeChannel.title')"
                  class="required"
                  @change="onStoreChannelChange"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="storeChannelId"
                  :items="storeChannelsList"
                  :clearable="true"
                  :label="$t('storeChannel.title')"
                  class="required"
                  @change="onStoreChannelChange"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL"
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="quantityAvailable"
                  :label="$t('voucherProduct.quantityAvailable')"
                  :name="$t('voucherProduct.quantityAvailable')"
                  disabled
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                xs12
                sm6
                md6>
                <v-text-field
                  v-model="quantityAvailable"
                  :label="$t('voucherProduct.quantityAvailable')"
                  :name="$t('voucherProduct.quantityAvailable')"
                  disabled
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL"
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="quantity"
                  :label="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL ? $t('voucherProduct.quantityPutUp') : $t('voucherProduct.quantityToTakeDown')"
                  :name="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL ? $t('voucherProduct.quantityPutUp') : $t('voucherProduct.quantityToTakeDown')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm12
                md12>
                <div class="text-left py-2 font-weight-bold uppercase">
                  <span>{{ $t('voucherProduct.infoFromSupplier') }}</span>
                </div>
                <div class="text-left py-2 font-weight-bold uppercase">
                  <span>{{ $t('voucherProduct.category') }}: <span class="font-weight-light">{{ getSupplierCategories() }}</span></span>
                </div>
                <!-- Image supplier -->
                <v-layout
                  layout
                  wrap>
                  <!-- Issuance img -->
                  <v-flex
                    v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                    class="text-center"
                    xs12
                    md6
                  >
                    <img
                      :src="supplierImage ? supplierImage : noImg"
                      class="issuance-img-class img-responsive"
                      alt="issuance-img">
                  </v-flex>
                  <!-- Album image for voucher product -->
                  <v-flex
                    v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                    xs12
                    md6
                  >
                    <div class="text-left pb-2 font-weight-bold font-small uppercase">
                      {{ $t('voucherProduct.albumImages') }}
                    </div>
                    <div
                      class="d-flex"
                      style="width: fit-content; flex-flow: row wrap;">
                      <div
                        v-for="(data, index) in supplierAlbumImages"
                        :key="data + index"
                        class="image-input image-input-active d-flex"
                        style="width: fit-content;">
                        <div
                          class="image-preview"
                          style="border: 1px solid #e5e5e5;">
                          <img
                            :src="data"
                            class="img-responsive h-100">
                        </div>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex
                    xs12
                    sm12
                    md12>
                    <v-textarea
                      v-model="supplierDescription"
                      :label="$t('voucherProduct.detail')"
                      :name="$t('voucherProduct.detail')"
                      disabled
                      type="text"
                      columns="4"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm12
                md12>
                <div class="text-left py-2 font-weight-bold uppercase">
                  <span>{{ $t('voucherProduct.infoFromAgent') }}</span>
                </div>
                <v-layout
                  layout
                  wrap>
                  <!-- Image agent -->
                  <v-flex
                    v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                    class="text-center"
                    xs12
                    md6
                  >
                    <image-picker
                      key="product-img"
                      :image-width="300"
                      :image-height="200"
                      :image-url="agentImageUrl"
                      exceptation-ratio="3:2"
                      max-size-upload="3MB"
                      recommend-dimension="300x200"
                      @onSelectedImageFile="onSelectIssuanceImg"
                      @onResetImage="onClickRemoveIssuanceImg"
                    />
                  </v-flex>
                  <!-- Agent album images for voucher product -->
                  <v-flex
                    v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                    xs12
                    md6
                  >
                    <div class="text-left pb-2 font-weight-bold font-small uppercase">
                      {{ $t('voucherProduct.albumImages') }}
                    </div>
                    <div
                      class="d-flex"
                      style="width: fit-content; flex-flow: row wrap;">
                      <div
                        v-for="(data, index) in agentAlbumImages"
                        :key="data + index"
                        class="image-input image-input-active d-flex"
                        style="width: fit-content;">
                        <div
                          class="image-preview"
                          style="border: 1px solid #e5e5e5;">
                          <img
                            :src="data"
                            class="img-responsive h-100">
                          <button
                            style="z-index: 999;"
                            class="btn btn-xs remove-file"
                            @click="removeFile(index)">
                            <i class="fa fa-trash " />
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="files.length < option.maxFileCount"
                        class="image-input image-input-tbd">
                        <div
                          style="position: relative;"
                          class="image-preview dropzone d-flex justify-content-center align-items-center"
                          @drop="loaddropfile"
                          @click="openinput">
                          <i
                            class="fa fa-plus text-success"
                            style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);"/>
                        </div>
                        <input
                          id="vue-file-upload-input"
                          type="file"
                          class="d-none"
                          @change="addImage">
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm12
                md12>
                <v-textarea
                  v-model="description"
                  :disabled="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL"
                  :label="$t('voucherProduct.detail')"
                  :name="$t('voucherProduct.detail')"
                  type="text"
                  columns="4"
                />
              </v-flex>
            </v-layout>
          </v-form>
          <v-form
            v-else-if="tabSelected === tabsSelect.CONFIG"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout
              wrap
              layout>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="issuanceId"
                  :items="issuanceList"
                  :label="$t('assignVoucher.chooseIssuance')"
                  class="required"
                  disabled
                  @change="onProductIssuanceConfigChangeIssuance"
                />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm6
                md6
              >
                <v-checkbox
                  v-model="bestSelling"
                  :label="$t('voucherProduct.bestSelling')" />
              </v-flex>
              <v-flex
                v-if="actionTypeSelected == actionVoucherProductType.UPDATE_PRODUCT_INFO"
                xs12
                sm12
                md12
              >
                <v-autocomplete
                  :disabled="actionTypeSelected == actionVoucherProductType.PUBLIC_ON_PORTAL || actionTypeSelected == actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
                  v-model="categoriesIds"
                  :items="categoriesList"
                  :clearable="true"
                  :label="$t('voucherProduct.category')"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ categoriesIds.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="configProduct.price"
                  :label="$t('voucherProduct.config.priceNormal')"
                  :name="$t('voucherProduct.config.priceNormal')"
                  type="text"
                  disabled
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="configProduct.pricePromoFormatter"
                  :label="$t('voucherProduct.config.pricePromo')"
                  :name="$t('voucherProduct.config.pricePromo')"
                  type="text"
                  @input="formatValue"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="configProduct.numberOfPurchasesEachTime"
                  :label="$t('voucherProduct.config.maxNumberOfVouchersPurchasedTime')"
                  :name="$t('voucherProduct.config.maxNumberOfVouchersPurchasedTime')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="configProduct.repeatTime"
                  :items="repeatTimeList"
                  :label="$t('voucherProduct.config.repeatTime')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <!-- Start date -->
              <v-flex
                xs12
                md6>
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      :label="$t('promotionCode.startTime')"
                      persistent-hint
                      class="required"
                      @blur="
                        date = parseDate(startDateFormatted)
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :min="minStartDate"
                    no-title
                    @input="menuStartDate = false"
                  />
                </v-menu>
              </v-flex>
              <!-- End date -->
              <v-flex
                xs12
                md6>
                <v-menu
                  ref="endStartDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      :label="$t('promotionCode.endTime')"
                      persistent-hint
                      class="required"
                      @blur="
                        date = parseDate(endDateFormatted)
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :min="minEndDate"
                    no-title
                    @input="menuEndDate = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>
          <v-form
            v-else
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm12
                md12
              >
                <div
                  v-for="(address, index) in usageSites"
                  :key="address + index"
                  class="font-medium mt-2">
                  <span class="d-block font-weight-bold">{{ index + 1 + '. ' + address.name }}</span>
                  <div v-if="address.fullAddress">
                    <address-label
                      :longitude="address.latitude"
                      :latitude="address.longitude">
                      {{ address.fullAddress }}
                    </address-label>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="actionTypeSelected === actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
          :disabled="quantityAvailable === 0"
          :loading="isLoading"
          small
          color="info darken-1"
          @click="onShowModalTakeDownQuantity"
        >{{ $t("voucherProduct.takeDownQuantity") }}
        </v-btn>
        <v-btn
          v-if="actionTypeSelected === actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
          :loading="isLoading"
          small
          color="warning darken-1"
          @click="onShowModalRemoveFromStore"
        >{{ $t("voucherProduct.removeFromStore") }}
        </v-btn>
        <v-btn
          v-if="actionTypeSelected !== actionVoucherProductType.TAKE_DOWN_FROM_PORTAL"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <quantity-to-take-down-modal
      ref="quantityToTakeDownModal"
      @onConfirm="confirmQuantityToTakeDown"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmRemoveFromStore"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ActionVoucherProductType from 'enum/actionVoucherProductType'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import axios from 'axios'
import StoreChannelType from 'enum/storeChannelType'
import stringUtils from 'utils/stringUtils'
import moment from 'moment'
import dateUtils from 'utils/dateUtils'
import QuantityToTakeDownModal from './QuantityToTakeDownModal'
import ConfirmModal from 'Components/ConfirmModal'
import AddressLabel from 'Components/AddressLabel.vue'
import ImagePicker from 'Components/ImagePicker.vue'

export default {
  components: {
    QuantityToTakeDownModal,
    AddressLabel,
    ImagePicker,
    ConfirmModal
  },
  data: () => ({
    valid: false,
    isShowModal: false,
    isLoading: false,
    issuanceProductList: [],
    issuanceProduct: null,
    issuanceId: null,
    issuanceList: [],
    quantityAvailable: 0,
    quantity: 0,
    noImg: require('@/assets/noimage.png'),
    option: {
      maxFileCount: 10
    },
    files: [],
    actionTypeSelected: null,
    actionVoucherProductType: {
      UPDATE_PRODUCT_INFO: ActionVoucherProductType.UPDATE_PRODUCT_INFO,
      PUBLIC_ON_PORTAL: ActionVoucherProductType.PUBLIC_ON_PORTAL,
      TAKE_DOWN_FROM_PORTAL: ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL
    },
    categoriesList: [],
    categoriesIds: [],
    agentImageUrl: null,
    agentImageFile: null,
    agentAlbumImages: [],
    supplierImage: null,
    supplierAlbumImages: [],
    media_server: {
      domain: '',
      token: ''
    },
    description: null,
    supplierDescription: null,
    storeChannelsList: [],
    storeChannelId: null,
    isShowInfo: true,
    repeatTimeList: stringUtils.RepeatTimeList,
    // Start issue expired date to customer
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false, // End issue expired date
    minStartDate: moment().format('YYYY-MM-DD'),
    // Start issue expired date to customer
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false, // End issue expired date
    minEndDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    configProduct: {
      pricePromo: null,
      pricePromoFormatter: null,
      numberOfPurchasesEachTime: null,
      repeatTime: null,
      price: null
    },
    tabsSelect: {
      INFO: 1,
      CONFIG: 2,
      ADDRESS: 3
    },
    tabSelected: 1,
    usageSites: [],
    indexRetrievingAddess: -1,
    bestSelling: false,
    confirmModalTitle: '',
    issuanceProductName: '',
    storeChannelsName: ''
  }),
  computed: {
    ...mapGetters([
      'GET_ISSUANCE_LIST_DATA',
      'GET_SNACK_BAR',
      'GET_TOKEN_MEDIA_SERVER_DATA'
    ])
  },
  watch: {
    /**
     * Issue expired date to customer
     */
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    /**
     * Issue expired date to customer
     */
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    GET_ISSUANCE_LIST_DATA () {
      let data = this.GET_ISSUANCE_LIST_DATA
      this.issuanceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: functionUtils.concatSuffixPrefix(data[i])
        }
        this.issuanceList.push(obj)
      }
      if (this.actionTypeSelected === this.actionVoucherProductType.UPDATE_PRODUCT_INFO) {
        // this.issuanceId = this.issuanceList.length > 0 ? this.issuanceList[0].value : null
        // get ProductIssuanceConfig
        this.getProductIssuanceConfig()
      }
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  methods: {
    /**
     * Tab select
     */
    onTabSelect: function (type) {
      this.tabSelected = type
    },
    /**
     * Store channel change
     */
    onStoreChannelChange: function () {
      let filter = {
        params: {
          issuanceId: this.issuanceId,
          workpieceId: this.issuanceProduct,
          storeChannelId: this.storeChannelId
        }
      }
      // eslint-disable-next-line eqeqeq
      if (this.actionTypeSelected == ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL) {
        this.GET_VOUCHERS_ON_PRODUCT_PORTAL(filter).then(
          function (res) {
            let data = res.data
            this.quantityAvailable = data.quantity
          }.bind(this)
        )
      }
      let index = this.storeChannelsList.findIndex(x => x.value === this.storeChannelId)
      this.storeChannelsName = index !== -1 ? this.storeChannelsList[index].text : ''
    },
    /**
     * Format value
     */
    formatValue: function (value) {
      value = functionUtils.formatInteger(value && value.toString())
      this.configProduct.pricePromoFormatter = functionUtils.convertFormatNumberToMoney(value)
      this.configProduct.pricePromo = value
    },
    // Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Get store channels list
     */
    getStoreChannelsList: function () {
      let entityId = sessionStorage.getItem('entityId')
      this.GET_STORE_CHANNELS_LIST().then(
        function (res) {
          let data = res.data
          this.storeChannelsList = []
          for (let i = 0, size = data.length; i < size; i++) {
            let storeChannelObj = {
              value: data[i].id,
              text: data[i].name,
              type: data[i].type
            }
            // eslint-disable-next-line eqeqeq
            if (data[i].type == StoreChannelType.PRODUCT_PORTAL && functionUtils.hasEntityRoles('hasManageWebPortal')) {
              this.storeChannelsList.push(storeChannelObj)
            // eslint-disable-next-line eqeqeq
            } else if (data[i].type == StoreChannelType.HARAVAN && functionUtils.hasEntityRoles('hasGiveVoucherToHaravan')) {
              this.storeChannelsList.push(storeChannelObj)
            // eslint-disable-next-line eqeqeq
            } else if (data[i].entity_default_store_channels_id !== null && data[i].entity_default_store_channels_id === entityId) {
              this.storeChannelsList.push(storeChannelObj)
            }
            // } else if (data[i].type == StoreChannelType.LIEN_VIET_POST_BANK && functionUtils.hasEntityRoles('hasGiveVoucherToLienViet')) {
            //   this.storeChannelsList.push(storeChannelObj)
            // } else if (data[i].type === StoreChannelType.MOBILE_PORTAL && functionUtils.hasEntityRoles('hasGiveVoucherToMobilePortal')) {
            //   this.storeChannelsList.push(storeChannelObj)
            // }
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getSupplierCategories: function () {
      let supplierCategoriesStr = ''
      for (let i = 0, size = this.supplierCategories.length; i < size; i++) {
        supplierCategoriesStr += this.supplierCategories[i].name + (i < size - 1 ? ', ' : '')
      }
      return supplierCategoriesStr
    },
    onChangeIssuancce: function (issuanceId) {
      let filter = {
        params: {
          issuanceId: issuanceId,
          workpieceId: this.issuanceProduct
        }
      }
      // eslint-disable-next-line eqeqeq
      if (this.actionTypeSelected == ActionVoucherProductType.PUBLIC_ON_PORTAL) {
        this.GET_QUANTITY_VOUCHER_PRODUCT_AVAILABLE_OF_ENTITY(filter).then(
          function (res) {
            let data = res.data.data
            this.quantityAvailable = data.quantity_voucher_available
          }.bind(this)
        )
      }
      if (this.storeChannelId && this.actionTypeSelected === ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL) {
        this.onStoreChannelChange()
      }
    },
    /**
     * Get quantity e voucher available of entity
     */
    getQuantityEVoucherAvailableOfEntity: function (workpieceId) {
      let filter = {
        params: {
          workpieceId: workpieceId,
          issuanceId: this.issuanceId
        }
      }
      this.GET_QUANTITY_VOUCHER_PRODUCT_AVAILABLE_OF_ENTITY(filter)
    },
    /**
     * Issuance product change
     */
    onIssuanceProductChange: function (id) {
      this.getIssuanceList(id)
      let index = this.issuanceProductList.findIndex(x => x.value === id)
      this.issuanceProductName = index !== -1 ? this.issuanceProductList[index].text : ''
    },
    /**
     * Get prefix suffix
     */
    getIssuanceList: function (workpieceId) {
      let filter = {
        params: {
          workpieceId: workpieceId,
          isPublicStoreChannel: this.actionTypeSelected === ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL
        }
      }
      this.GET_ISSUANCE_LIST(filter)
    },
    /**
     * Load drop file
     */
    loaddropfile: function (e) {
      e.preventDefault()
      e.stopPropagation()
      alert('ok')
    },
    /**
     * Open input
     */
    openinput: function () {
      document.getElementById('vue-file-upload-input').click()
    },
    /**
     * Add image
     */
    addImage: function (e) {
      e.preventDefault()
      const tmpFiles = e.target.files
      if (tmpFiles.length === 0) {
        return false
      }
      const file = tmpFiles[0]
      this.files.push(file)
      const self = this
      const reader = new FileReader()
      reader.onload = function (e) {
        self.agentAlbumImages.push(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    /**
     * Remove file
     */
    removeFile: function (index) {
      this.files.splice(index, 1)
      this.agentAlbumImages.splice(index, 1)
      document.getElementById('vue-file-upload-input').value = null
    },
    /**
     * Upload
     */
    upload: function () {
      alert('Check console to see uploads')
    },
    /**
     * Select issuance img
     */
    onSelectIssuanceImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.getResultFile(files[0])
      this.agentImageUrl = URL.createObjectURL(files[0])
    },
    getResultFile: function (file) {
      let vm = this
      vm.agentImageFile = file
    },
    /**
     * Remove issuance img
     */
    onClickRemoveIssuanceImg: function () {
      this.agentImageUrl = null
      this.agentImageFile = null
    },
    /**
     * Upload issuance image
     */
    uploadAgentImage: async function () {
      let formData = new FormData()
      formData.append('file', this.agentImageFile)
      formData.append('directory', 'eVoucher/Product/AgentImages')
      formData.append('forceOverride', true)
      await axios({
        method: 'POST',
        timeout: 6000,
        url: this.media_server.domain + '/api/v1/upload/',
        data: formData,
        headers: { Authorization: `JWT ${this.media_server.token}` }
      }).then(
        async function (res) {
          let data = res.data
          this.agentImageUrl = data.domain + data.url
        }.bind(this)
      ).catch(
        async function () {
          this.agentImageUrl = null
        }.bind(this)
      )
    },
    /**
     * Upload issuance image
     */
    uploadAgentAlbumImages: async function () {
      for (let i = 0, size = this.files.length; i < size; i++) {
        // eslint-disable-next-line eqeqeq
        if (typeof this.files[i] === 'object') {
          let formData = new FormData()
          formData.append('file', this.files[i])
          formData.append('directory', 'eVoucher/Product/AgentAlbumImages')
          formData.append('forceOverride', true)
          await axios({
            method: 'POST',
            timeout: 6000,
            url: this.media_server.domain + '/api/v1/upload/',
            data: formData,
            headers: { Authorization: `JWT ${this.media_server.token}` }
          }).then(
            async function (res) {
              let data = res.data
              let imageUrl = data.domain + data.url
              this.agentAlbumImages[i] = imageUrl
            }.bind(this)
          ).catch(
            async function () {
              this.agentAlbumImages[i] = null
            }.bind(this)
          )
        }
      }
    },
    /**
     * On confirm
    */
    onConfirm: async function () {
      if (this.$refs.form.validate()) {
        let startTime = dateUtils.formatSqlDate(this.startDateFormatted)
        let endTime = dateUtils.formatSqlDate(this.endDateFormatted)
        let filter = {
          issuanceId: this.issuanceId,
          workpieceId: this.issuanceProduct,
          quantity: this.quantity,
          imageUrl: this.agentImageUrl,
          albumImageUrls: this.agentAlbumImages,
          description: this.description,
          categories: this.categoriesIds,
          storeChannelId: this.storeChannelId,
          pricePromo: functionUtils.isEmptyString(this.configProduct.pricePromo) ? null : this.configProduct.pricePromo,
          numberOfPurchasesEachTime: functionUtils.isEmptyString(this.configProduct.numberOfPurchasesEachTime) ? null : this.configProduct.numberOfPurchasesEachTime,
          repeatTime: this.configProduct.repeatTime,
          startTime: startTime,
          endTime: endTime,
          bestSelling: this.bestSelling
        }
        // eslint-disable-next-line eqeqeq
        if (this.actionTypeSelected == ActionVoucherProductType.PUBLIC_ON_PORTAL) {
          let storeChannelSelected = this.storeChannelsList.filter((item) => {
            return item.value === this.storeChannelId
          })
          if (storeChannelSelected[0].type === StoreChannelType.HARAVAN) {
            this.ON_GIVE_VOUCHER_TO_HARAVAN(filter).then(
              function () {
                this.isShowModal = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
              }.bind(this)
            ).catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    'styleType': ToastType.ERROR
                  })
                }
              }.bind(this)
            )
          } else {
            this.PUBLIC_VOUCHER_PRODUCT_ON_STORE_CHANNEL(filter).then(
              function () {
                this.isShowModal = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.$emit('updateVoucherProductSuccess')
              }.bind(this)
            ).catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    'styleType': ToastType.ERROR
                  })
                }
              }.bind(this)
            )
          }
        // eslint-disable-next-line eqeqeq
        } else if (this.actionTypeSelected == ActionVoucherProductType.TAKE_DOWN_FROM_PORTAL) {
          this.TAKE_BACK_VOUCHERS_ON_PORTAL(filter).then(
            function () {
              this.isShowModal = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
              this.$emit('updateVoucherProductSuccess')
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  styletype: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        } else {
          if (this.agentImageFile) {
            await this.uploadAgentImage()
            filter['imageUrl'] = this.agentImageUrl
          }
          await this.uploadAgentAlbumImages()
          if (this.tabSelected === this.tabsSelect.CONFIG) {
            this.createProductIssuanceConfig()
          } else {
            filter['albumImageUrls'] = this.agentAlbumImages
            this.CREATE_VOUCHER_PRODUCT_AGENT(filter).then(
              function () {
                this.isShowModal = false
                this.$emit('updateVoucherProductSuccess')
              }.bind(this)
            )
          }
        }
      }
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.agentImageUrl = null
      this.agentImageFile = null
      this.agentAlbumImages = []
      this.supplierImage = null
      this.supplierAlbumImages = []
      this.files = []
      this.categoriesIds = []
      this.categoriesList = []
      this.description = null
      this.issuanceId = null
      this.issuanceProduct = null
      this.quantity = 0
      this.storeChannelId = null
      this.quantityAvailable = 0
    },
    /**
     * Show modal
     */
    onShowModal: function (actionType, productInfo) {
      this.setDefaultData()
      this.actionTypeSelected = actionType
      this.tabSelected = this.tabsSelect.INFO
      if (productInfo) {
        this.issuanceProduct = productInfo.id
        this.supplierAlbumImages = productInfo.extraInfo.supplier.album_image_urls ? productInfo.extraInfo.supplier.album_image_urls : []
        this.supplierImage = productInfo.image
        this.supplierCategories = productInfo.extraInfo.supplier.categories ? productInfo.extraInfo.supplier.categories : []
        if (productInfo.extraInfo.agent) {
          this.agentAlbumImages = productInfo.extraInfo.agent.album_image_urls
          // this.configProduct.pricePromo = productInfo.extraInfo.agent.price_promo
          // this.configProduct.pricePromoFormatter = productInfo.extraInfo.agent.price_promo ? functionUtils.convertFormatNumberToMoney(productInfo.extraInfo.agent.price_promo.toString()) : null
          // this.configProduct.numberOfPurchasesEachTime = productInfo.extraInfo.agent.number_of_purchases_each_time
          // this.configProduct.repeatTime = productInfo.extraInfo.agent.repeat_time
          this.configProduct.price = functionUtils.convertFormatNumberToMoney(productInfo.price.toString())
          // Start date
          if (productInfo.extraInfo.agent.start_time) {
            this.startDate = dateUtils.formatComponentDate(productInfo.extraInfo.agent.start_time)
            this.startDateFormatted = dateUtils.formatBeautyDate(productInfo.extraInfo.agent.start_time)
          } else {
            this.startDate = moment().format('YYYY-MM-DD')
            this.startDateFormatted = moment().format('DD/MM/YYYY')
          }
          // End date
          if (productInfo.extraInfo.agent.end_time) {
            this.endDate = dateUtils.formatComponentDate(productInfo.extraInfo.agent.end_time)
            this.endDateFormatted = dateUtils.formatBeautyDate(productInfo.extraInfo.agent.end_time)
          } else {
            this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')
            this.endDateFormatted = moment().add(1, 'days').format('DD/MM/YYYY')
          }
          // this.bestSelling = productInfo.extraInfo.agent.best_selling
        } else {
          this.agentAlbumImages = []
          this.configProduct.pricePromo = null
          this.configProduct.numberOfPurchasesEachTime = null
          this.configProduct.repeatTime = null
        }
        this.files = [...this.agentAlbumImages]
        this.agentImageUrl = productInfo.extraInfo.agent.image_url
        this.supplierDescription = productInfo.description
        this.description = productInfo.extraInfo.agent.description ? productInfo.extraInfo.agent.description : this.supplierDescription
        // let agentCategories = productInfo.extraInfo.agent.categories
        // for (let i = 0, size = agentCategories.length; i < size; i++) {
        //   this.categoriesIds.push(agentCategories[i].id)
        // }
        this.usageSites = []
        productInfo.usageSites.forEach((site) => {
          let item = {
            id: site.id,
            name: site.name,
            code: site.code,
            longitude: site.longitude,
            latitude: site.latitude,
            province: site.province,
            district: site.district,
            ward: site.ward,
            street: site.street,
            fullAddress: site.street
          }
          this.usageSites.push(item)
        })
        this.indexRetrievingAddess = 0
        this.doRetrievingFullAddress()
        this.issuanceId = productInfo.issuanceId
      } else {
        this.issuanceProduct = null
        this.supplierAlbumImages = []
      }
      if (actionType === this.actionVoucherProductType.TAKE_DOWN_FROM_PORTAL ||
        actionType === this.actionVoucherProductType.PUBLIC_ON_PORTAL ||
        actionType === this.actionVoucherProductType.UPDATE_PRODUCT_INFO) {
        this.GET_WORKPIECE_LIST_OF_VOUCHER_PRODUCT_ALREADY_EXIST().then(
          function (res) {
            let data = res.data
            for (let i = 0, size = data.length; i < size; i++) {
              let obj = {
                value: data[i].id,
                text: data[i].name,
                code: data[i].code
              }
              this.issuanceProductList.push(obj)
            }
          }.bind(this)
        )
        if (this.issuanceProduct && actionType === this.actionVoucherProductType.UPDATE_PRODUCT_INFO) {
          this.getIssuanceList(this.issuanceProduct)
        }
      }
      this.GET_VOUCHER_PRODUCT_CATEGORIES().then(
        function (res) {
          this.isLoading = false
          let data = res.data
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              value: data[i].id,
              text: data[i].name
            }
            this.categoriesList.push(obj)
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
      this.GET_TOKEN_MEDIA_SERVER()
      this.isShowModal = true
      this.issuanceList = []
      this.getStoreChannelsList()
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    onShowModalTakeDownQuantity: function () {
      if (this.$refs.form.validate()) {
        this.$refs.quantityToTakeDownModal.onShowModal(this.quantityAvailable)
      }
    },
    confirmQuantityToTakeDown: function (data) {
      this.quantity = data

      let startTime = dateUtils.formatSqlDate(this.startDateFormatted)
      let endTime = dateUtils.formatSqlDate(this.endDateFormatted)
      let filter = {
        issuanceId: this.issuanceId,
        workpieceId: this.issuanceProduct,
        quantity: this.quantity,
        imageUrl: this.agentImageUrl,
        albumImageUrls: this.agentAlbumImages,
        description: this.description,
        categories: this.categoriesIds,
        storeChannelId: this.storeChannelId,
        pricePromo: functionUtils.isEmptyString(this.configProduct.pricePromo) ? null : this.configProduct.pricePromo,
        numberOfPurchasesEachTime: this.configProduct.numberOfPurchasesEachTime,
        repeatTime: this.configProduct.repeatTime,
        startTime: startTime,
        endTime: endTime,
        bestSelling: this.bestSelling
      }
      this.TAKE_BACK_VOUCHERS_ON_PORTAL(filter).then(
        function () {
          this.onStoreChannelChange()
          this.$refs.quantityToTakeDownModal.onCloseModal()
          this.isShowModal = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.$emit('updateVoucherProductSuccess')
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    onShowModalRemoveFromStore: function () {
      if (this.$refs.form.validate()) {
        this.$refs.confirmModal.onShowModal()
        this.confirmModalTitle = this.$t('voucherProduct.notiRemoveVoucherFromStore', { '0': this.issuanceProductName, '1': this.storeChannelsName })
      }
    },
    confirmRemoveFromStore: function () {
      let startTime = dateUtils.formatSqlDate(this.startDateFormatted)
      let endTime = dateUtils.formatSqlDate(this.endDateFormatted)
      let filter = {
        issuanceId: this.issuanceId,
        workpieceId: this.issuanceProduct,
        quantity: null,
        imageUrl: this.agentImageUrl,
        albumImageUrls: this.agentAlbumImages,
        description: this.description,
        categories: this.categoriesIds,
        storeChannelId: this.storeChannelId,
        pricePromo: this.configProduct.pricePromo,
        numberOfPurchasesEachTime: this.configProduct.numberOfPurchasesEachTime,
        repeatTime: this.configProduct.repeatTime,
        startTime: startTime,
        endTime: endTime,
        bestSelling: this.bestSelling
      }
      this.REMOVE_VOUCHER_FROM_STORE_CHANNEL(filter).then(
        function () {
          this.onStoreChannelChange()
          // this.$refs.quantityToTakeDownModal.onCloseModal()
          this.isShowModal = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.$emit('updateVoucherProductSuccess')
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    getProductIssuanceConfig: function () {
      let filter = {
        params: {
          issuanceId: this.issuanceId
        }
      }
      this.GET_PRODUCT_ISSUANCE_CONFIG(filter).then(
        function (res) {
          let data = res.data
          this.configProduct.pricePromo = data.price_promo
          this.configProduct.pricePromoFormatter = data.price_promo ? functionUtils.convertFormatNumberToMoney(data.price_promo.toString()) : null
          this.configProduct.numberOfPurchasesEachTime = data.number_of_purchases_each_time
          this.configProduct.repeatTime = data.repeat_time
          this.categoriesIds = data.categories
          // Start date
          if (data.start_time) {
            this.startDate = dateUtils.formatComponentDate(data.start_time)
            this.startDateFormatted = dateUtils.formatBeautyDate(data.start_time)
          }
          //  else {
          //   this.startDate = moment().format('YYYY-MM-DD')
          //   this.startDateFormatted = moment().format('DD/MM/YYYY')
          // }
          // End date
          if (data.end_time) {
            this.endDate = dateUtils.formatComponentDate(data.end_time)
            this.endDateFormatted = dateUtils.formatBeautyDate(data.end_time)
          }
          // else {
          //   this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')
          //   this.endDateFormatted = moment().add(1, 'days').format('DD/MM/YYYY')
          // }
          this.bestSelling = data.best_selling
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    createProductIssuanceConfig: function () {
      if (this.$refs.form.validate()) {
        let startTime = dateUtils.formatSqlDate(this.startDateFormatted)
        let endTime = dateUtils.formatSqlDate(this.endDateFormatted)
        let filter = {
          issuanceId: this.issuanceId,
          quantity: this.quantity,
          categories: this.categoriesIds,
          storeChannelId: this.storeChannelId,
          pricePromo: functionUtils.isEmptyString(this.configProduct.pricePromo) ? null : this.configProduct.pricePromo,
          numberOfPurchasesEachTime: functionUtils.isEmptyString(this.configProduct.numberOfPurchasesEachTime) ? null : this.configProduct.numberOfPurchasesEachTime,
          repeatTime: this.configProduct.repeatTime,
          startTime: startTime,
          endTime: endTime,
          bestSelling: this.bestSelling
        }
        this.CREATE_PRODUCT_ISSUANCE_CONFIG(filter).then(
          function (res) {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.$emit('updateVoucherProductSuccess')
          }.bind(this)
        ).catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    doRetrievingFullAddress: function () {
      if (this.indexRetrievingAddess >= 0 && this.usageSites && this.indexRetrievingAddess < this.usageSites.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.usageSites[this.indexRetrievingAddess].province,
            districtCode: this.usageSites[this.indexRetrievingAddess].district,
            wardCode: this.usageSites[this.indexRetrievingAddess].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.usageSites[this.indexRetrievingAddess].street)
            this.usageSites[this.indexRetrievingAddess].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddess++
            this.doRetrievingFullAddress()
          }.bind(this)
        )
      }
    },
    onProductIssuanceConfigChangeIssuance: function () {
      this.getProductIssuanceConfig()
    },
    ...mapActions([
      'GET_WORKPIECE_LIST_OF_VOUCHER_PRODUCT_ALREADY_EXIST',
      'GET_ISSUANCE_LIST',
      'GET_QUANTITY_VOUCHER_PRODUCT_AVAILABLE_OF_ENTITY',
      'GET_VOUCHER_PRODUCT_CATEGORIES',
      'ON_SHOW_TOAST',
      'PUBLIC_VOUCHER_PRODUCT_ON_STORE_CHANNEL',
      'GET_TOKEN_MEDIA_SERVER',
      'CREATE_VOUCHER_PRODUCT_AGENT',
      'GET_STORE_CHANNELS_LIST',
      'ON_GIVE_VOUCHER_TO_HARAVAN',
      'GET_VOUCHERS_ON_PRODUCT_PORTAL',
      'TAKE_BACK_VOUCHERS_ON_PORTAL',
      'REMOVE_VOUCHER_FROM_STORE_CHANNEL',
      'GET_PRODUCT_ISSUANCE_CONFIG',
      'CREATE_PRODUCT_ISSUANCE_CONFIG',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.issuance-img-class{
  /* width: 100%; */
  width: 300px;
  height: 200px;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.image-input {
  padding: 3px;
}

.image-preview {
  width: 96px;
  height: 96px;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.dropzone {
  width: 96px;
  height: 96px;
}
.dropzone {
  border: 1px dashed green;
  border-radius: 7%;
}
.remove-file {
  position: absolute;
  margin-top: 5px;
  margin-left: 0px;
  color: rgba(0,0,0,0.5);
  padding: 1px 6px;
  background-color: rgba(0,0,0,0.3);

}
.remove-file:hover {
  color: #555;
  background-color: #f4f5f7;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
